.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(49, 45, 124);
  background: linear-gradient(
    90deg,
    rgba(49, 45, 124, 1) 9%,
    rgba(104, 64, 148, 1) 25%,
    rgba(92, 92, 201, 1) 78%
  );
  border-radius: 0px 20px 20px 0px;
  height: 60px;
  width: 88%;
  transition: top 0.6s;
}

.toolbarNavigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.toolbarLogo {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  margin-left: 1rem;
  font-weight: bold;
}

.spacer {
  flex: 1;
}

.toolbarNavigationItems ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbarNavigationItems li {
  padding: 0 0.5rem;
  font-weight: bold;
}

.toolbarNavigationItems a {
  color: white;
  text-decoration: none;
}

.toolbarNavigationItems a:hover,
.toolbarNavigationItems a:active {
  color: rgb(43, 156, 156);
  color: rgb(61, 199, 199);
}

.toolbar--hidden {
  top: -60px;
}
