html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.closed,
.table > div.closed {
  display: none !important;
}

tr.opener td {
  padding: 20px;
  padding-top: 50px;
  font-size: 20px;
}

tr.product_details h2 {
  padding: 20px;
  font-size: 16px;
}

tr.product_details {
  padding-bottom: 40px;
}
