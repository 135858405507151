.CaseStatus {
  overflow-x: scroll;
  padding: 1rem 0.25rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.CaseStatus[class] table {
  border-collapse: collapse;

  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.25);

  overflow: hidden;

  min-width: 100%;
}

.CaseStatus[class] th,
.CaseStatus[class] td {
  font-size: smaller;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  padding: 0.5rem;

  /* text-shadow: 0 0 0.5rem rgba(0,0,0,0.5); */
  color: white;
}

.CaseStatus[class] td {
  font-weight: normal !important;
  padding-top: 0;
}


.CaseStatus th.yes,
.CaseStatus td.yes {
  background-color: #2DAAFA;
}
.CaseStatus th.no,
.CaseStatus td.no {
  background-color: #FF3300;
}
