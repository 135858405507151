
.AddressList__ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 20;
  background-color: #fff;
  top: 30px;
  width: 100%;
}

.AddressList__li {
  background-color: #fff;
  width: calc(100% + 20px);
  flex: 1 1 auto;
  padding: 10px 10px;
  cursor: pointer;
}

.AddressList__li:hover {
  background-color: #2daafa;
}
