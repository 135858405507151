:root {
  --border-clr: rgb(210, 169, 202, 0.3);
  --border-clr: #c7b5db;

  --boxShadow: 6px 7px 14px -9px rgba(102, 123, 187, 1);
  --sub-btn-clr: #684094;
  --sub-btn_light-clr: #8d819b;
  --boldFont-errorMsg-clr: red;
  --highlight-clr: steelblue;
  --error-boder-clr: red;
  color: rgb(50, 53, 54);
}

.App {
  text-align: center;
  /* font-family: "Montserrat", sans-serif; */
}

.chart {
  height: 1200px;
}

.forms {
  display: inline-block;
  border: 2px solid var(--border-clr);
  box-shadow: var(--boxShadow);
  background-color: #fbf8f8;
  /*width: max-content;*/
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  /* font-family: "Montserrat", sans-serif; */
}

.form2 {
  display: inline-block;
  padding-left: 95px;
  border-radius: 55px;
  /* font-family: "Montserrat", sans-serif; */
  position: relative;
  top: 120px;
  left: 35%;
}

.errorMessage {
  color: var(--boldFont-errorMsg-clr);
  font-size: 10px;
}

.succeddMessage {
  color: green;
  font-size: 10px;
}

.on-blur {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}

input,
select {
  margin: 7px;
  border-radius: 15px;
  border: 1px solid #ccc;
  outline: none;
  min-width: 215px;
}

button {
  font: inherit;
  text-align: center;
  padding: 0.5rem 2rem;
  margin: 35px 0 15px;
  border-radius: 15px;
  background-color: var(--sub-btn-clr);
  cursor: pointer;
  outline: none;
  color: white;
  font-weight: 800;
}

button:disabled {
  background-color: var(--sub-btn_light-clr);
  cursor: default;
}

.button__symbol {
  background: none;
  border: none;
  font-size: 1.5rem;
}

h2 {
  font-size: 30px;
}

.form1 {
  display: inline-block;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.form3,
.PostIdentSearch {
  margin-top: 2%;
  position: relative;
  top: 70px;
}

.form3flex {
  display: flex;
  flex-direction: row;
  margin: 0 0 30px 0;
  align-items: center;
}

.form3button {
  margin: 0;
}

.inputform3 {
  width: 150px;
}

.fixed {
  position: fixed;
  top: 10px;
}

.Section__container___3YYTG {
  float: right;
}

/* 4 elements of sign in/out box */

#root:nth-child(3n) {
  float: right;
}

.Nav__navBar___xtCFA {
  position: absolute !important;
  top: 0px !important;
  right: 0px;
  border: none !important;
  font-size: 14px;
}

.Button__button___vS7Mv {
  border-radius: 15px !important;
  height: 10px !important;
  line-height: 0.1em !important;
  /* margin-top: auto !important; */
  font-size: 8px !important;
  max-width: 80px !important;
  min-width: 100px !important;
  padding: 8px 6px !important;
  /* position: fixed;
  left: 2.5rem;
  bottom: 20px; */
}

.Nav__navItem___1LtFQ {
  margin-right: auto !important;
  font-size: 12px;

  position: fixed;
  right: 5px;
}

.Nav__navRight___1QG2J {
  position: fixed;
  top: 0px;
  right: 10px;
}

/* drop-down styling */
.select {
  display: block;
  font-size: 12px;
  font-family: inherit;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 83%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 5px 0;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 1px rgba(175, 136, 207, 0.04);
  /*border-radius: 1.5em;*/
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #ecf2f8 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.select::-ms-expand {
  display: none;
}

.select:hover {
  border: 1px solid rgb(0, 172, 231, 0.7);
}

.select:focus {
  border-color: #ccc;
  box-shadow: 0 0 2px 2px rgba(138, 186, 238, 0.7);
  color: #222;
  outline: none;
}

.select option {
  font-weight: 12px;
  font-family: inherit;
}

.navbar {
  width: 100%;
  padding: 10px 0;
  position: fixed;
  top: 0;
  transition: top 0.6s;
}

.navbar--hidden {
  top: -100px;
}

.dontDisplay {
  display: none;
}

.table,
.table.gridjs-table {
  display: table !important;
  box-shadow: var(--boxShadow);
  border: 2px solid var(--border-clr);
  border-radius: 25px;
  border-spacing: 0px;
}


.table>div {
  display: table-row !important;
}

.table>div:nth-of-type(1) {
  font-weight: bold;
  font-family: inherit;
}

.table>div>div,
.table>div>time {
  display: table-cell !important;
  padding: 12px;
  border-bottom: 1px solid var(--border-clr);
}

.table>div:first-child>div,
.table>div:first-child>time {
  border-bottom: 2px solid var(--border-clr);
}

.table>div:last-child>div,
.table>div:last-child>time {
  border: none;
}

.personIdHighlight {
  color: var(--highlight-clr);
  margin-left: 20px;
}

.orderHistory {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orderHistory .gridjs-search {
  float: none;
}

.orderHistory .gridjs-table {
  width: auto !important;
}

.orderHistory .gridjs-tr,
.listElement > div {
  background-color: #fff;
}

.orderHistory .gridjs-tr:last-child div:first-child,
.listElement:last-child div:first-child {
  border-bottom-left-radius: 23px;
}

.orderHistory .gridjs-tr:last-child div:last-child,
.listElement:last-child div:last-child {
  border-bottom-right-radius: 23px;
}

.orderHistory .gridjs-tr:nth-child(even),
.listElement:nth-child(even) > div {
  background-color: var(--border-clr);
}

.orderHistory .gridjs-tr:hover,
.listElement:hover > div {
  background-color: #e5ecfc;
  overflow: hidden;
  cursor: pointer;
}

.orderHistory .table td.gridjs-td {
  background-color: transparent;
}

.orderHistory .table .gridjs-thead th {
  background-color: white;
  color: black;
}

.orderHistory .gridjs-pagination .gridjs-pages button {
  color: #6b7280;
  margin: 0 5px 0 0;
}

.orderHistory [data-column-id="createdAt"] {
  min-width: 170px;
}

.orderHistory [data-column-id="strategy"],
.orderHistory [data-column-id="businessPartnerId"],
.orderHistory [data-column-id="orderId"],
.orderHistory [data-column-id="paymentType"] {
  min-width: 110px;
}

.orderHistory [data-column-id="conditionId"] {
  min-width: 60px;
}

.orderHistory [data-column-id="confirmed"],
.orderHistory [data-column-id="externalId"] {
  min-width: 100px;
}

.orderHistory [data-column-id="status"] {
  min-width: 75px;
}

.orderHistory [data-column-id="amount"] {
  min-width: 160px;
}

.orderHistory [data-column-id="communicationEmail"] {
  min-width: 80px;
}

.orderHistory [data-column-id="ipsId"]{
  min-width: 65px;
}

.orderHistory [data-column-id="actions"]{
  min-width: 75px;
}

.orderHistoryList {
  margin: 0 0 10px 0;
}

.hideContent {
  display: none;
}

.dataFolders tr td {
  font-family: inherit;
  font-weight: 700;
  padding: 15px;
  border-radius: 20px;
  margin-left: 15px;
  text-align: center;
  padding: 0.5rem 2rem;
  box-shadow: var(--boxShadow);
  background-color: var(--border-clr);
}

.dataFolders tr td:hover {
  cursor: pointer;
  background-color: #e5ecfc;
}

.listElement:nth-child(2) {
  border-top: 2px solid rgb(210, 169, 202, 0.3);
  border-top: 3px solid black !important;
  /* background-color: khaki; */
  border: 2px solid black;
}

/* styling radio buttons form2 */

input[type="radio"] {
  min-width: 0px;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #e3e6e3;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  /* signed out bgr-clr
  background-color: #ff9900; */
  background-color: #ffa500;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

/* form2 border changes color on filling out */
/* input[required]:placeholder-shown {
  margin: 7px;
  border-radius: 15px;
  border: 1px solid #ccc;
  outline: none;
} */

input,
select {
  border: 2px solid var(--border-clr);
  /* box-shadow: var(--boxShadow); */
}

/*Registration löshen msg and btn */
.sicherMsg {
  margin: 55px 0 0 25px;
}

.deleteBtn {
  margin: 15px 0 0 25px;
  background-color: red;
}

.-loading {
  overflow: hidden;
}

.-loading .SearchResults {
  opacity: 0.5;
}

.SearchInput,
.SearchResults,
.orderHistory__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5%;
}

.orderHistory.-loading,
.orderHistory__content.-loading {
  opacity: 0.2;
}

.-loading:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}

.-loading:after {
  z-index: 99;
  width: 2em;
  height: 2em;
  margin: auto;

  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;

  border: 0.25em solid #464faa;
  border-top-color: #b21c73;
  border-bottom-color: #464faa;

  -webkit-animation: loading 1.5s infinite linear;
  animation: loading 1.5s infinite linear;
}

@-webkit-keyframes loading {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sort-button {
  /* background-color: rgb(214, 218, 221); */
  color: white;
  padding: 0 7px;
  font-size: 12px;
  margin-left: 10px;
}

.formChangeAccount {
  background-color: white;
  position: fixed;
  left: calc(50% - 222px);
  top: calc(50% - 166px);
  z-index: 2;
  width: auto;
}

.formChangeAccount button[type="submit"] { margin: 35px 17px 15px 0; }

.formChangeAccount h2 { text-align: center; }

.formChangeAccount input { min-width: 220px; }

.orderHistoryElements tr td {
  /* background-color: hotpink; */
  padding: 12px;
}

.orderHistory__content {}

#list {
  list-style-type: none;
  font-size: 18px;
  line-height: 1em;
  margin-left: auto;
  margin-top: 20px;
  cursor: pointer;
}

.formChangeAccount label span {
  min-width: 150px;
  display: inline-block;
}

.PersonItem {
  font-weight: bold;
}

.activeTab {
  /* color: yellow; */
  background-color: rgb(179, 195, 241) !important;
}

.PostIdentSearch {
  margin: auto;
  width: 80%;
}

.PostidentList {
  width: 100%;
  margin-top: 50px;
}

.PostidentList .head {
  font-weight: bold;
}

.PostidentList>div {
  padding: 10px;
  cursor: pointer;
}

.PostidentList .listElement {
  display: flex;
}

.PostidentList .listElement:hover {
  background-color: inherit;
}

.PostidentList .PostidentDetails {
  margin-top: 10px;
  margin-bottom: 5px;
}

.PostidentList .PostidentDetails div {
  padding-top: 5px;
  cursor: pointer;
}

.PostidentList>div:nth-child(even) {
  background: #c7b5db;
}

.PostidentList .listElement div {
  width: 20%;
}

.PostIdentSearch .SearchInput label span {
  min-width: 150px;
  display: inline-block;
}

.react-bootstrap-daterangepicker-container {
  cursor: pointer;
}

.on {
  background-color: hotpink;
  color: yellow;
}

.salutationSelect {
  display: flex;
}

.buttonSmall {
  position: relative;
  bottom: 30px;
  height: 25px;
  text-align: center;
  font-size: 12px;
  padding: 0 10px;
  margin-left: 15px;
  border-radius: 10px;
}

.selectSmall {
  height: 25px;
  padding: 0 10px;
  width: 170px;
  border-radius: 4px;
}

.buttonAccountChange {
  height: 25px;
  text-align: center;
  font-size: 12px;
  padding: 0 10px;
  border-radius: 10px;
}

.SearchDetails {
  display: flex;
  flex-direction: column;
}

.dataFolders,
.DetailsPassword,
.DetailsSavingsPlans,
.personinfos__container {
  display: flex;
  justify-content: center;
}

.personinfos__status {
  font-size: 1.5rem;
  margin: 1.5rem 0 0 1.5rem;
}

.personinfos--documents {}

.personinfos--documents__item {
  cursor: pointer;
  margin: 5px 0 0 0;
}

.personinfos--documents__item--name {
  margin: 0 0 0 5px;
}

.personinfos__element {
  display: flex;
  align-items: center;
  position: relative;
}

.personinfos__element__title {
  width: 160px;
  margin: 7px 0px;
}

.personinfos__element__value {
  margin: 7px 0px 7px 5px;
}

.personinfos__column {
  flex: 1 1;
  margin: 0 20px 0 0;
  min-width: 400px;
}

.personinfos__element input,
.personinfos__element select {
  padding: 0 5px;
  min-width: 100px;
}

.personinfos__element input {
  height: 20px;
  width: calc(100% - 168px);
}


.personinfos__element select {
  height: 24px;
  width: calc(100% - 156px);
}

.personinfos__container .input--disabled,
.personinfos__container select[disabled],
.personinfos__container select[disabled]>option {
  color: rgb(50, 53, 54);
  font-weight: 500;
  font-size: inherit;
  opacity: 1;
  border: none !important;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 7px 0px;
}

.personinfos__container .invalid__field,
.modal .invalid__field {
  border-color: var(--error-boder-clr);
}

.personinfos_buttons {
  display: flex;
  justify-content: flex-start;
  flex: 0 0 66rem;
}

.personinfos_buttons>button {
  margin: 3rem 2rem 0 0;
}

.DetailsPassword,
.DetailsProducts,
.orderHistory,
.DetailsSavingsPlans {
  margin: 2rem 1rem;
}

.DetailsPerson {
  margin: 2rem auto;
}

.DetailsPassword__button {
  margin: 0 20px 0 0;
}

.DetailsPassword__input {
  margin: 0 20px 0 0;
}

.DetailsPassword__text {
  margin: 0 0 30px 0;
}

.DetailsPassword__message {
  margin: 30px 0 0 0;
}

.table .product_details>div {
  display: flex !important;
  flex-direction: column;
}

.table .product_details>div>div {
  padding-bottom: 20px;
}

.DetailsProducts__content {
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  background-color: #f5f4f6;
  color: rgb(50, 53, 54);
  flex: 1;
}

.DetailsProducts__bp {
  display: flex;
  position: relative;
  align-items: center;
  flex-wrap: wrap;
}

.DetailsProducts__bp__descType {
  flex: 0 0 15rem;
}

.DetailsProducts__bp__descIban {
  flex: 0 0 25rem;
}

.DetailsProducts__bp__balanceDate {
  flex: 1 0 11rem;
}

.DetailsProducts__bp__button__RegProtocol {
  flex: 0 0 13rem;
}

.DetailsProducts__bp__button__RefChange {
  flex: 0 0 13rem;
}

.DetailsProducts__bp__button__Delete {
  flex: 0 0 8rem;
}

.DetailsProducts__bp__button__Open {
  flex: 0 0 2rem;
}

.DetailsProducts__ips__buttonGroup {
  flex: 0 0 19rem;
  display: flex;
  justify-content: space-between;
}

.DetailsProducts__bp__button,
.DetailsProducts__ips__button {
  margin: 0 0 0 10px;
  padding: 0.16rem 0.47rem;
}

.DetailsProducts__header {
  margin: 0 0 0.5rem 0;
}

.DetailsProducts__ips {
  background: white;
}

.DetailsProducts__ips__group {
  display: flex;
  flex-direction: column;
}

.DetailsProducts__ips__desc {
  display: flex;
  justify-content: space-between;
}

.DetailsProducts__ips__content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flexTable__column {
  display: flex;
  flex-direction: column;
}

.flexTable__row {
  display: flex;
  flex-direction: row;
}

.flexTable__row__header {
  font-weight: 600;
}

/* .OPEN, .CLOSED, .INCLOSE, .PENDING { color: white; } */

.OPEN {
  background-color: #a6f0a6;
}

.CLOSED {
  background-color: #a09aa7;
}

.INCLOSE {
  background-color: #ccc0da;
}

.PENDING, .STRATEGYCHANGE {
  background-color: #e99700;
}

.DetailsPerson__Geburtsurkune {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 0 0;
}

.DetailsPerson__Geburtsurkune:first-child {
  margin: 2rem;
}

.GeburtsurkundeSimple {
  margin: 0 0 2rem 0;
  padding: 1rem;
  border-radius: 20px;
  border: 2px solid var(--border-clr);
  box-shadow: var(--boxShadow);
  background-color: #fbf8f8;
  min-width: 35rem;
}

.GeburtsurkundeSimple__Title {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.GeburtsurkundeSimple__Title__Text {
  flex: 1 0 29rem;
  margin: 0;
}

.GeburtsurkundeSimple__Title__Symbol {
  flex: 0 0 1rem;
}

.GeburtsurkundeSimple__Row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0 0 0;
}


.GeburtsurkundeSimple__Label {}

.GeburtsurkundeSimple__Input {
  flex: 0 0 22rem;
  margin: 0;
}

.GeburtsurkundeSimple__Readonly,
.GeburtsurkundeSimple__Select,
.GeburtsurkundeSimple__Radio {
  flex: 0 0 calc(22rem + 8px);
  margin: 0;
}

.GeburtsurkundeSimple__Radio input:first-child {
  margin: 7px 7px 7px 0;
}

.savingsPlan__accounDesc {
  font-weight: 700;
}

.activateCoupon_container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 25px;
  padding: 1rem;
  margin: 2rem 0 0 0;
  width: 97%;
  border: 2px solid var(--border-clr);
}

.activateCoupon_container select,
.activateCoupon_container input {
  padding: 5px 5px 5px 25px;
  border: 2px solid #c7b5db;
  border-radius: 15px;
  margin: 5px auto;
  cursor: pointer;
}

.fieldElement input[disabled],
.fieldElement select[disabled] {
  color: rgb(50, 53, 54);
  font-weight: 500;
  opacity: .5;
  cursor: default;
}

.activateCoupon_container label { cursor: pointer; }

.activateCoupon_container button {
  width: 150px;
  align-self: center;
}

.activateCoupon_container .fieldElement {
  max-width: 100%;
  margin: 1rem 0;
}

.couponHistory .table,
.voucherHistory .table {
  margin-top: 2rem;
}

.couponTerms {

}

.activateCoupon_container input[type='checkbox'] {
  min-width: 20px;
  width: 20px;
  height: 20px;
  margin-right: 1rem;
}

/* .couponHistory .card,
.voucherHistory .card {
  width: 100%;
} */

.couponHistory__segmentControl,
.voucherHistory__segmentControl {
  border-radius: 20px !important;
  width: 200px;
  height: 36px;
  box-shadow: var(--boxShadow);
  background-color: var(--border-clr) !important;
}

div[class*='__CheckedSegment'] {
  background-color: rgb(179, 195, 241);
}
