.sideDrawer {
  height: 100%;
  background-color: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 330px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.sideDrawer.open {
  transform: translateX(0);
}

.sideDrawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sideDrawer li {
  margin: 0.5rem 0;
}

.sideDrawer a {
  color: rgb(48, 45, 125);
  text-decoration: none;
  font-size: 1.2rem;
}

.sideDrawer a:hover,
.sideDrawer a:active {
  color: rgb(61, 199, 199);
}
