.modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background: white;
  border: 1px solid;
  border-radius: 15px;
}

.modal-header {
  padding: 10px;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid;
}

.modal-body {
  padding: 10px;
  flex: 1 0 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-footer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.modal-footer button {
  margin: 0;
}
