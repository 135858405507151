.RegistrationProtocol {
  height: 390px;
  top: calc(20vh - 80px);
  width: 880px;
  margin: 0px calc(50vw - 475px);
}

.RegistrationProtocol-List {
  display: flex;
  flex-direction: column;
}

.RegistrationProtocol-Item {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 5px 10px;
}

.RegistrationProtocol-Item:hover {
  text-decoration: underline;
  cursor: pointer;
}

.RegistrationProtocol-Item__icon {
  flex: 0 0 1rem;
  padding: 0 5px 0 0;
}

.RegistrationProtocol-Item__displayName {
  flex: 0 0 27rem;
}

.RegistrationProtocol-Item__status {
  flex: 0 0 6rem;
}

.RegistrationProtocol-Item__createdAt {
  flex: 0 0 10rem;
}

.RegistrationProtocol-Item__updatedAt {
  flex: 0 0 10rem;
}
