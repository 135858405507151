.DetailsCases th,
.DetailsCases td {
  padding: 0.5rem 1rem;
  text-align: left;
  vertical-align: top;
}

.DetailsCases td:first-child {
  font-weight: bold;
  padding-left: 0;
}

.DetailsCases td:last-child {
  word-break: break-all;
}

.DetailsCases table {
  min-width: 100%;
  border-collapse: collapse;
}

.DetailsCases tbody tr:hover {
  background-color: #e1f3fe;
}

.DetailsCases input {
  min-width: 0;
}

.card {
  margin: 2rem;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}

.card>h2,
.card>h3 {
  margin-top: 0;
  font-weight: 200;
  margin-bottom: 0.5rem;
}

.card>h3 {
  font-weight: normal;
  margin-bottom: 1rem;
}


.card>h3:only-child {
  margin-bottom: 0.25rem;
}


/*  max-width disabled, cause: On bigger screens the container was positioned
  to the left where the rest of the app is in the middle */
.case {
  /* max-width: 90rem; */
  background: #f5f4f6;
}
.case.-PRODUCT {
  background: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.125);
}

.case .card {
  margin: 1rem 0 0;
  padding: 1rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  background: white;
}

.case a {
  color: #2daafa !important;
  text-decoration: none;
  font-weight: bold;
}

.case h2 span {
  display: inline-block;
  margin: 0 0.25em;
}

.case-button {
  margin: 0 0 0 20px;
  padding: 3px 15px;
  font-size: 1rem;
  vertical-align: 4px;
}

.case-quickinfo {
  margin-top: 0 !important;
}

.case-quickinfo p {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.collapse-link {
  display: block;
  float: right;
  cursor: pointer;
}

.search-link:after {
  content: " 🔎";
}
