.toggleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 20px;
  width: 28px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggleButton:focus {
  outline: none;
}

.toggleButtonLine {
  width: 100%;
  height: 2px;
  background-color: white;
}
